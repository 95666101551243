body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media screen and (max-width: 600px) {
    table {
        text-align: center;
        table-layout: fixed;
        width: 650px;
    }
    table th p {
        margin: 0 -100%;
        text-align: center;
        white-space: nowrap;
        transform-origin: 50% 50%;
        transform: rotate(90deg);
        -ms-transform: rotate(90deg); /* IE 9 */
        -moz-transform: rotate(90deg); /* Firefox */
        -webkit-transform: rotate(90deg); /* Safari and Chrome */
        -o-transform: rotate(90deg); /* Opera */
    }

    table th p:before {
        content: '';
        width: 0;

        padding-top: 110%; /* takes width as reference, + 10% for faking some extra padding */
        display: inline-block;
        vertical-align: middle;
    }

    table tr .tw-flex {
        display: block;
    }
}
